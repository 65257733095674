/**
 * @generated SignedSource<<1cf9b07e2d5233fd94559bbc1e2425ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LoginHeaderQuery$variables = {
  subdomain: string;
};
export type LoginHeaderQuery$data = {
  readonly publicBuilding: {
    readonly name: string;
  } | null;
  readonly publicLicense: {
    readonly logoPath: string | null;
    readonly name: string;
  } | null;
};
export type LoginHeaderQuery = {
  response: LoginHeaderQuery$data;
  variables: LoginHeaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subdomain"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "subdomain",
    "variableName": "subdomain"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoPath",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PublicLicense",
        "kind": "LinkedField",
        "name": "publicLicense",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PublicBuilding",
        "kind": "LinkedField",
        "name": "publicBuilding",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PublicLicense",
        "kind": "LinkedField",
        "name": "publicLicense",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PublicBuilding",
        "kind": "LinkedField",
        "name": "publicBuilding",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3c8a5910742aa4e9001772974e76981f",
    "id": null,
    "metadata": {},
    "name": "LoginHeaderQuery",
    "operationKind": "query",
    "text": "query LoginHeaderQuery(\n  $subdomain: String!\n) {\n  publicLicense(subdomain: $subdomain) {\n    name\n    logoPath\n    id\n  }\n  publicBuilding(subdomain: $subdomain) {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2446a1b5e58e823ba32ff376c7ee5ff2";

export default node;
